import React from 'react'

type PropTypes = {
  data?: readonly (Queries.SchemaMarkupFragment | null)[] | null
}

const toSchema = (data: Queries.SchemaMarkupFragment | null) => {
  switch (data?.__typename) {
    case 'Contentstack_metadata__schema_markup__video':
      return {
        '@context': 'https://schema.org',
        '@type': 'VideoObject',
        embedUrl: data.embed_url,
        name: data.title,
        description: data.video_description,
        thumbnailUrl:
          data.video_thumbnails.length > 1
            ? data.video_thumbnails.map((thumbnail) => thumbnail?.url)
            : data.video_thumbnails[0]?.url,
        uploadDate: new Date(data.uploaded_date).toISOString(),
        duration: `PT${data.duration_in_secs || 1}S`,
      }
    default:
      return undefined
  }
}

export const SchemaMarkup = ({ data }: PropTypes) => {
  if (!data) {
    return null
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(
        data.length > 1
          ? {
              '@context': 'https://schema.org',
              '@type': 'ItemList',
              itemListElement: data.map(toSchema).filter(Boolean),
            }
          : toSchema(data[0]),
        null,
        2,
      )}
    </script>
  )
}
