import React from 'react'

import { BreadcrumbLink } from '../Breadcrumb'

type Props = {
  pageTitle?: string
  grandParent?: {
    title: string
    url: string | null
  } | null
  parent?: {
    title: string
    pageUrl?: string | null
  } | null
}

export const getBreadcrumbSchemaMarkupData = (
  breadcrumbLinks: BreadcrumbLink[],
) => ({
  '@context': 'https://schema.org',
  '@type': 'BreadcrumbList',
  itemListElement: breadcrumbLinks.map((link, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: link.text,
    item: link.target ? `https://www.richardson.com${link.target}` : undefined,
  })),
})

export const SchemaMarkupBreadcrumb = ({
  pageTitle,
  grandParent,
  parent,
}: Props) => {
  const breadcrumbLinks: BreadcrumbLink[] = [{ text: pageTitle || '' }]
  if (parent) {
    breadcrumbLinks.unshift({
      text: parent.title,
      target: parent?.pageUrl || undefined,
    })
  }
  if (grandParent) {
    breadcrumbLinks.unshift({
      text: grandParent.title,
      target: grandParent?.url || undefined,
    })
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(getBreadcrumbSchemaMarkupData(breadcrumbLinks), null, 2)}
    </script>
  )
}
