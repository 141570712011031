import { graphql, HeadProps, PageProps } from 'gatsby'
import React, { FC } from 'react'

import ContactUsForm from '../components/ContactUsForm'
import Layout from '../components/Layout'
import LocationsGrid from '../components/LocationsGrid'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'

const ContactUsPage: FC<PageProps<Queries.ContactUsPageQuery, any>> = ({
  data,
  pageContext,
}) => {
  const { contentstackPageContactUs: pageData } = data
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const metaTitle = pageData?.title || pageData?.metadata?.page_title
  const metaDesc = pageData?.metadata?.description
  const metaImg = pageData?.metadata?.image

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={pageContext.slug}
      title={metaTitle}
    >
      <ContactUsForm formContent={pageData?.form?.[0]} />
      <LocationsGrid offices={pageData?.offices?.[0]?.offices} />
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.ContactUsPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageContactUs?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageContactUs?.title}
    />
  </>
)

export default ContactUsPage

export const query = graphql`
  query ContactUsPage($pageId: String, $locale: String) {
    contentstackPageContactUs(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      form {
        ...LeadGenForm
      }
      offices {
        offices {
          id
          title
          address
          email
          phone
          map_image {
            description
            imgixImage {
              gatsbyImageData(width: 100, imgixParams: { q: 60 })
            }
          }
        }
      }
    }
  }
`
