import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

interface BodySectonProps {
  noTopPadding: boolean
  noBottomPadding: boolean
}

interface ArticleContainerProps {
  align?: 'Left' | 'Right' | string | null
}

export const BodySection = styled.section<BodySectonProps>`
  padding: 110px 0;
  padding-top: ${(props) => props.noTopPadding && 55}px;
  padding-bottom: ${(props) => props.noBottomPadding && 8}px;

  @media (max-width: 768px) {
    padding: 48px 0;
  }
`

export const StickySidebar = styled.div`
  grid-column: 10 / 13;
  grid-row-start: 1;
  position: relative;

  aside {
    position: sticky;
    top: calc(${rspTheme.navHeight} + 24px);
    right: 0;
    background-color: #ffffff;
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-column: 1 / -1;

    aside {
      position: static;
      max-width: 360px;
    }
  }
`

export const MobileDownloadContainer = styled.div`
  display: none;

  @media (max-width: ${rspTheme.breakpoints.values.md}px) {
    grid-column: 2 / 10;
    display: block;
    margin-top: 24px;
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-column: 1 / -1;
  }
`

export const DesktopDownloadContainer = styled.div`
  display: block;

  @media (max-width: ${rspTheme.breakpoints.values.md}px) {
    display: none;
  }
`

export const ShareContainer = styled.div`
  grid-column: 2 / 10;
  margin-top: 40px;

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-column: 1 / -1;
    text-align: center;
    margin-top: 24px;

    > div {
      justify-content: center;
    }
  }
`

export const ArticleContainer = styled.div<ArticleContainerProps>`
  max-width: ${(props) => (props.align ? '857px' : '675px')};
  grid-column: ${(props) => (props.align ? '1 / 10' : '2 / 10')};
  display: inline-grid;

  h2,
  h3 {
    color: ${rspTheme.palette.primary.main};
    font-size: ${rspTheme.typography.h3.fontSize};
    font-weight: ${rspTheme.typography.h3.fontWeight};
    line-height: ${rspTheme.typography.h3.lineHeight};
    letter-spacing: ${rspTheme.typography.h3.letterSpacing};
    margin: 40px 0 16px;

    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      margin: 24px 0 36px;
    }
  }

  p {
    display: ${(props) => (props.align ? 'inline-grid' : 'block')};
    gap: 24px;
    grid-template-columns: ${(props) => (props.align ? 'auto auto' : '1fr')};
    align-items: center;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      grid-template-columns: 1fr;
    }
  }

  p,
  ul,
  ol {
    color: ${rspTheme.palette.text.primary};
    font-size: ${rspTheme.typography.body1.fontSize};
    font-weight: ${rspTheme.typography.body1.fontWeight};
    line-height: ${rspTheme.typography.body1.lineHeight};
    letter-spacing: ${rspTheme.typography.body1.letterSpacing};
    margin-bottom: 16px;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      line-height: 1.9;
    }
  }

  blockquote {
    font-style: italic;
    margin: 0 1.6rem 1.6rem;
  }

  ul {
    list-style: none;

    li {
      position: relative;
      margin-bottom: 8px;

      &::before {
        content: '•';
        position: absolute;
        color: ${rspTheme.palette.primary.light};
        font-size: 2rem;
        font-weight: 400;
        left: -16px;
        line-height: 1.5;
      }
    }
  }

  a {
    color: inherit;
    transition: all 0.2s ease-in-out;
    border-bottom: 1px solid #000;
    padding-bottom: 2px;
    text-decoration: none;

    &:focus,
    &:hover {
      color: ${rspTheme.palette.primary.main};
    }
  }

  strong {
    font-weight: 400;
    font-size: inherit;
  }

  img {
    display: block;
    order: ${(props) => (props.align === 'Left' ? '0' : '1')};
    margin: 0;
    max-width: 100%;
    height: auto;
    border-radius: 8px;

    @media (max-width: ${rspTheme.breakpoints.values.md}px) {
      order: 0;
      max-width: 90%;
      margin: 0 auto;
    }
  }

  strong {
    font-weight: 400;
    font-size: inherit;
  }

  ul,
  ol {
    margin-block-start: 0;
    padding-inline-start: 16px;
  }

  iframe {
    --frame-width: 640px;
    border-radius: 8px;
    margin: 16px 0;
    /* For Vimeo embeds; the width and height are set by inline styles, so we need
    to use !important to override that at points where the iFrame is too wide;
    dividing by 1.78 preserves the aspect ratio */
    width: var(--frame-width) !important;
    height: calc(var(--frame-width) / 1.78) !important;

    @media (max-width: 1100px) {
      --frame-width: 600px;
    }
    @media (max-width: 1024px) {
      --frame-width: 500px;
    }
    @media (max-width: 840px) {
      --frame-width: 400px;
    }
    @media (max-width: 680px) {
      --frame-width: 340px;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    grid-column: 1 / -1;
  }
`

export const PageBreak = styled.div`
  grid-column: 1 / -1;
  width: 100vw;
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: ${rspTheme.palette.primary.background};
  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
    margin-top: 48px;
    margin-bottom: 32px;
  }
`
