import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import Wrapper from '../UI/Wrapper'
import { ImageContainer, LocationsSection, OfficeCard } from './styles'

interface LocationsGridProps {
  offices?: any[] | readonly any[] | null
}

const LocationsGrid: FC<LocationsGridProps> = ({ offices }) => (
  <LocationsSection>
    <Wrapper>
      <Grid className="officesGrid" component="ul" container>
        {offices?.map((office: any) => {
          const image = getImage(office?.map_image?.imgixImage)
          return (
            <OfficeCard key={office?.id}>
              {image ? (
                <ImageContainer>
                  <GatsbyImage
                    alt={office?.map_image?.description || ''}
                    image={image}
                  />
                </ImageContainer>
              ) : null}
              <Typography className="officeSubhead" component="h2" variant="h4">
                {office?.title}
              </Typography>
              <Typography className="officeBody" component="p" variant="body2">
                {office?.address}
              </Typography>
              {office?.phone ? (
                <Typography
                  className="officeBody"
                  component="p"
                  variant="body2"
                >
                  <a href={`tel:${office?.phone}`}>{office?.phone}</a>
                </Typography>
              ) : null}
              {office?.email ? (
                <Typography
                  className="officeBody"
                  component="p"
                  variant="body2"
                >
                  <a href={`mailto:${office?.email}`}>Email</a>
                </Typography>
              ) : null}
            </OfficeCard>
          )
        })}
      </Grid>
    </Wrapper>
  </LocationsSection>
)

export default LocationsGrid
