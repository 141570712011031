import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const LocationsSection = styled.section`
  padding: 114px 0 124px;

  .officesGrid {
    justify-content: center;
    gap: 32px;
    color: #888888;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .officeSubhead {
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.5;
    margin-bottom: 16px;
    min-height: 50px;
  }

  .officeBody {
    font-size: 1.4rem;
    text-transform: uppercase;

    a {
      color: inherit;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 72px 0 88px;
  }
`

export const OfficeCard = styled.li`
  width: calc(20% - 32px);
  min-width: 170px;
  text-align: center;
  margin: 0;
  padding: 0;
`

export const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto 16px;

  .gatsby-image-container {
    width: 100%;
    height: 100%;
  }
`
