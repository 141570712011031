import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import parse from 'html-react-parser'
import React, { FC, useState } from 'react'

import {
  defaultInitialPardotFormValues,
  fieldError,
  fieldErrorText,
  nextDisabled,
  useForm,
} from '../../utils/form/useForm'
import { FormContent } from '../../utils/form/validate'
import { cleanString } from '../../utils/helpers'
import { useHandleSubmitWithRecaptcha } from '../../utils/recaptcha'
import GoogleAnalyticsHiddenFields from '../GoogleAnalyticsHiddenFields'
import { ArticleContainer } from '../PageBody/styles'
import Button from '../UI/Button/Button'
import Wrapper from '../UI/Wrapper'
import { BottomMessage, ContactUsSection } from './styles'

interface ContactUsFormProps {
  formContent: FormContent
  className?: string
}

const ContactUsForm: FC<ContactUsFormProps> = ({
  formContent,
  className = '',
}) => {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const GDPRCountries = formContent?.countries?.[0]?.reference
    ?.filter((country) => country?.tags?.includes('gdpr'))
    .map((country) => cleanString(country?.copy))

  const formId = 'contactUsForm'

  const form = useForm({
    formId,
    endpoint: 'want-more-info',
    errors: formContent,
    onSuccess: () => {
      setIsSubmitted(true)
      const scrollTarget = document?.getElementById('contactUsFormContainer')
      scrollTarget?.scrollIntoView()
    },
    emailField: 'businessEmail',
    initialValues: defaultInitialPardotFormValues,
  })

  const handleSubmit = useHandleSubmitWithRecaptcha(
    form.handleSubmit,
    form.isValid,
  )

  return (
    <ContactUsSection className={className} id="contactUsFormContainer">
      <Wrapper>
        {isSubmitted ? (
          <Typography className="successMessage" component="p" variant="h3">
            {formContent?.success_message}
          </Typography>
        ) : (
          <>
            <Typography className="headline" component="h2" variant="h1">
              {formContent?.headline}
            </Typography>
            {formContent?.body ? (
              <div className="intro">
                <ArticleContainer>{parse(formContent?.body)}</ArticleContainer>
              </div>
            ) : null}
            <form className="contactUsForm" id={formId} onSubmit={handleSubmit}>
              <Grid
                alignItems="flex-start"
                container
                justifyContent="space-between"
              >
                <GoogleAnalyticsHiddenFields />
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'firstName')}
                  helperText={fieldErrorText(form, 'firstName')}
                  id="firstName"
                  label={cleanString(formContent?.first_name_label?.[0]?.copy)}
                  name="firstName"
                  onChange={form.handleChange}
                  value={form.values.firstName}
                  variant="filled"
                />
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'lastName')}
                  helperText={fieldErrorText(form, 'lastName')}
                  id="lastName"
                  label={cleanString(formContent?.last_name_label?.[0]?.copy)}
                  name="lastName"
                  onChange={form.handleChange}
                  value={form.values.lastName}
                  variant="filled"
                />
              </Grid>
              <Grid
                alignItems="flex-start"
                container
                justifyContent="space-between"
              >
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'companyName')}
                  helperText={fieldErrorText(form, 'companyName')}
                  id="companyName"
                  label={cleanString(
                    formContent?.company_name_label?.[0]?.copy,
                  )}
                  name="companyName"
                  onChange={form.handleChange}
                  value={form.values.companyName}
                  variant="filled"
                />
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'jobTitle')}
                  helperText={fieldErrorText(form, 'jobTitle')}
                  id="jobTitle"
                  label={cleanString(formContent?.job_title_label?.[0]?.copy)}
                  name="jobTitle"
                  onChange={form.handleChange}
                  value={form.values.jobTitle}
                  variant="filled"
                />
              </Grid>
              <Grid
                alignItems="flex-start"
                container
                justifyContent="space-between"
              >
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'numberOfSellers')}
                  helperText={fieldErrorText(form, 'numberOfSellers')}
                  id="numberOfSellers"
                  label={cleanString(
                    formContent?.number_of_sellers_label?.[0]?.copy,
                  )}
                  name="numberOfSellers"
                  onChange={form.handleChange}
                  value={form.values.numberOfSellers}
                  variant="filled"
                />
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'businessEmail')}
                  helperText={fieldErrorText(form, 'businessEmail')}
                  id="businessEmail"
                  label={cleanString(
                    formContent?.business_email_label?.[0]?.copy,
                  )}
                  name="businessEmail"
                  onChange={form.handleChange}
                  value={form.values.businessEmail}
                  variant="filled"
                />
              </Grid>
              <Grid
                alignItems="flex-start"
                container
                justifyContent="space-between"
              >
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'phoneNumber')}
                  helperText={fieldErrorText(form, 'phoneNumber')}
                  id="phoneNumber"
                  label={cleanString(
                    formContent?.phone_number_label?.[0]?.copy,
                  )}
                  name="phoneNumber"
                  onChange={form.handleChange}
                  value={form.values.phoneNumber}
                  variant="filled"
                />
                <TextField
                  className="textField textFieldHalf"
                  error={fieldError(form, 'country')}
                  helperText={fieldErrorText(form, 'country')}
                  id="country"
                  label={cleanString(formContent?.countries_label?.[0]?.copy)}
                  name="country"
                  onChange={form.handleChange}
                  select
                  value={form.values.country}
                  variant="filled"
                >
                  {formContent?.countries?.[0]?.reference?.map((country) => (
                    <MenuItem
                      key={country?.id}
                      value={cleanString(country?.copy)}
                    >
                      {cleanString(country?.copy)}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <TextField
                className="textField textArea"
                error={fieldError(form, 'trainingInterests')}
                helperText={fieldErrorText(form, 'trainingInterests')}
                id="trainingInterests"
                label={cleanString(formContent?.interests_label?.[0]?.copy)}
                multiline
                name="trainingInterests"
                onChange={form.handleChange}
                value={form.values.trainingInterests}
                variant="filled"
              />
              {GDPRCountries && GDPRCountries.includes(form.values.country) ? (
                <FormGroup className="gdpr-consent">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="GDPRConsent"
                        name="GDPRConsent"
                        onChange={form.handleChange}
                        value={form.values.GDPRConsent}
                      />
                    }
                    label={formContent?.gdpr_consent_language}
                  />
                  {form.touched.GDPRConsent &&
                  Boolean(form.errors.GDPRConsent) ? (
                    <span className="errorText">{form.errors.GDPRConsent}</span>
                  ) : null}
                </FormGroup>
              ) : null}

              <div className="smallText">
                {formContent?.privacy_policy_cta?.[0]?.copy
                  ? parse(formContent.privacy_policy_cta?.[0]?.copy)
                  : null}
              </div>

              <Grid
                container
                justifyContent={
                  className === 'technology' ? 'space-between' : 'center'
                }
              >
                {className === 'technology' &&
                formContent?.bottom_message?.[0] ? (
                  <BottomMessage>
                    {parse(formContent?.bottom_message?.[0]?.copy ?? '')}
                  </BottomMessage>
                ) : null}
                <Button
                  disabled={nextDisabled(form) || form.isSubmitting}
                  isLoading={form.isSubmitting}
                  type="submit"
                  variant="primary"
                >
                  {formContent?.submit_text ?? ''}
                </Button>
              </Grid>
            </form>
          </>
        )}
      </Wrapper>
    </ContactUsSection>
  )
}

export default ContactUsForm
