import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ContactUsSection = styled.section`
  padding: 94px 0 66px;
  background-color: ${rspTheme.palette.primary.background};
  position: relative;

  &.technology {
    background: rgb(87, 66, 114);
    background: radial-gradient(
      circle,
      rgba(87, 66, 114, 1) 0%,
      rgba(30, 24, 71, 1) 100%
    );
    > div {
      max-width: 752px;
    }
    h2.headline {
      color: white;
    }
    p {
      color: white;
    }
    a {
      border-bottom: 1px solid #3796ea;
    }
    a,
    .smallText a {
      color: #3796ea;
    }
    button {
      color: #ffffff;
      background-color: #ab7b82;
      border: 1px solid #ab7b82;
    }
  }

  .headline {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 32px;
  }

  .intro {
    max-width: 77ch;
    margin-bottom: 48px;
    line-height: 1.75;
  }

  .successMessage {
    color: ${rspTheme.palette.primary.main};
    margin-bottom: 24px;
  }

  .smallText {
    margin: 0 0 32px;
    font-size: ${rspTheme.typography.caption.fontSize};
    line-height: ${rspTheme.typography.caption.lineHeight};

    a {
      color: ${rspTheme.palette.primary.main};
      text-decoration-color: transparent;
      transition: 0.3s ease-in-out;

      &:hover,
      &:focus-visible {
        text-decoration-color: inherit;
      }
    }
  }

  .contactUsForm {
    .errorText {
      color: #f44336;
      font-size: 1.3rem;
      line-height: 1.5;
      font-weight: 400;
      margin-left: 3.2rem;
      margin-top: 0.8rem;
    }

    .gdpr-consent {
      margin-bottom: 32px;
      padding-left: 16px;

      .MuiFormControlLabel-root {
        align-items: flex-start;
      }
    }
    .textField {
      width: 100%;
      min-width: 200px;
      margin-bottom: 12px;

      &.textFieldHalf {
        width: 48%;

        @media (max-width: ${rspTheme.breakpoints.values.sm}px) {
          width: 100%;
        }
      }

      .MuiInputBase-input {
        background-color: ${rspTheme.palette.primary.background};
        color: ${rspTheme.palette.primary.main};
        border: 1px solid #f3f1f5;
      }
      .MuiFormLabel-root {
        color: ${rspTheme.palette.primary.main};
      }

      .MuiFilledInput-root {
        background-color: transparent;
      }

      .MuiInputBase-input {
        background-color: #ffffff;
        border-radius: 4px;
        margin-bottom: 24px;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
      }

      .MuiFilledInput-input {
        padding: 24px 12px 8px;
      }

      .MuiFilledInput-underline::after {
        border-bottom-color: transparent;
      }

      .MuiFilledInput-underline::before {
        border-bottom-color: transparent;
      }

      .MuiFormControl-root {
        position: relative;
      }

      .MuiFormLabel-root {
        color: ${rspTheme.palette.primary.main};
      }

      .MuiFormHelperText-root {
        position: absolute;
        bottom: 2px;
        letter-spacing: -0.6px;
      }

      .MuiFormHelperText-contained {
        margin-left: 0;
        width: 100%;
      }

      .MuiSelect-iconFilled {
        top: 16px;
      }

      .MuiFilledInput-multiline {
        min-height: 148px;
        padding: 0;

        textarea {
          width: 100%;
          min-height: 148px;
          height: unset;
        }
      }
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 62px 0 40px;

    .headline {
      font-size: 2.4rem;
      margin-bottom: 24px;
    }

    .body {
      margin-bottom: 24px;
    }
  }
`

export const BottomMessage = styled.div`
  p {
    font-size: 3rem;
    @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
`
